import '../../styles/pages/spring-summer-2022/leading-voice-in-radio.scss';

import React from "react";
import Layout from '../../components/layout';
import { Helmet } from "react-helmet"
import ScrollAnimation from 'react-animate-on-scroll';
import MoreStories from "../../components/spring-summer-2022-stories/more-stories"
// import { withPrefix } from 'gatsby'
import Vimeo from '@u-wave/react-vimeo';

import socialBanner from "../../images/spring-summer-2022/social-banners/leading-voice-in-radio.jpg";

import titleGraphic from "../../images/spring-summer-2022/leading-voice-in-radio/title-graphic.svg";
import radioWaves from "../../images/spring-summer-2022/leading-voice-in-radio/radio-waves.png";
import comouche from "../../images/spring-summer-2022/leading-voice-in-radio/comouche.jpg";



export default class Index extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    var pageTitle = 'A Leading Voice in L.A. Radio';
    var pageDesc = 'A communications alumnus discusses his journey and career as co-host of one of the region’s most popular morning shows.';
    var slug = 'leading-voice-in-radio';


    return (
      <Layout headerStyle="white" locationInfo={this.props.location} issue="spring-summer-2022">
        <Helmet>
          <title>{pageTitle}</title>
          <body className={slug} />

          <meta name="description" content={pageDesc} />

          {/* Schema.org markup for Google+ */}
          <meta itemprop="name" content={pageTitle} />
          <meta itemprop="description" content={pageDesc} />
          <meta itemprop="image" content={socialBanner} />

          {/* Twitter Card data */}
          <meta name="twitter:title" content={pageTitle} />
          <meta name="twitter:description" content={pageDesc} />
          <meta name="twitter:image:src" content={socialBanner} />

          {/* Open Graph data */}
          <meta property="og:title" content={pageTitle} />
          <meta property="og:image" content={socialBanner} />
          <meta property="og:image:alt" content={pageTitle} />
          <meta property="og:description" content={pageDesc} />
        </Helmet>
        <div className="hero story animated fadeIn">
          <div class="hero-left">
            <div className="text">
              <h1 className="animated fadeInDown delay-1s"><img src={titleGraphic} alt={pageTitle} /></h1>
              <p className="sub-heading animated fadeInDown delay-1s">A communications alumnus discusses his journey and career as co-host of one of the region’s most popular morning shows.</p>
              <span className="animated fadeInDown delay-1s byline">By Kendra Morales / Photos By Matt Gush</span>
            </div>
          </div>
          <div className='hero-right' />
          <img src={radioWaves} alt="" className='animated fadeInUp delay-1s radio-waves' />
        </div>
        <article className="wrap small">

          <ScrollAnimation animateOnce={true} animateIn="fadeIn">
            <figure className="align-wide align-wide-large">
              <Vimeo
                video="709669098"
                autoplay={false}
                responsive={true}
                showPortrait={false}
                showTitle={false}
                showByline={false}
                style={{ backgroundColor:'#000000' }}
              />
            </figure>
          </ScrollAnimation>

          <ScrollAnimation animateOnce={true} animateIn="fadeIn">

          <p><span className="intro-text">JON COMOUCHE</span> ’17 (B.A. communications-entertainment studies) is a co-host on 104.3 MYfm’s “Valentine in the Morning” show, alongside Sean Valentine and Jillian Escoto. Comouche got his start in radio broadcasting as a senior at Cal State Fullerton through a for-credit internship with iHeartRadio Los Angeles.</p>

          <p>After graduating, Comouche joined iHeartRadio’s promotions team. He worked his way toward becoming a part-time co-host on 102.7 KIIS FM’s “On Air With Ryan Seacrest,” and eventually, a full-time morning co-host on 104.3 MYfm.</p>

          <figure className="align-wide">
            <img src={comouche} alt="Jon Comouche sits in front of microphone and audio equipment" />
            <figcaption>Jon Comouche</figcaption>
          </figure>

          <p>“It feels great to be constantly surrounded by music and creative individuals who share a lot of the same passions and interests,” Comouche says. “My favorite part about my role is that I’m essentially in the driver’s seat to bring music, excitement and community to as many people as possible.”</p>

          </ScrollAnimation>
          <ScrollAnimation animateOnce={true} animateIn="fadeIn">
          
          <p>
            <strong>HOW DID YOU DEVELOP YOUR ON-AIR VOICE?<br /></strong>
            What definitely helped was starting a podcast. I was also lucky enough to work for some really great shows, like producing for the Ryan Seacrest morning show. I would literally take the tapes of Ryan Seacrest through the show and mimic them. I would learn how he said things, and I’d repeat them back to myself when I was alone in my room. I just practiced and worked on it as much as possible. 
          </p>

          </ScrollAnimation>
          <ScrollAnimation animateOnce={true} animateIn="fadeIn">
          
          <p>
            <strong>WHAT HAS SURPRISED YOU MOST ABOUT WORKING IN RADIO?</strong><br />
            I thought the radio industry was all glamour and that everyone on the show was a celebrity. Sure, artists come into the building, but everyone isn’t hanging out with them all day. The people creating the various shows are all just regular people. 
          </p>

          </ScrollAnimation>
          <ScrollAnimation animateOnce={true} animateIn="fadeIn">

          <figure><iframe src='https://anchor.fm/csufofficial/embed/episodes/What-does-it-take-to-make-it-in-the-radio-industry-e1ik7cl' height='102px' width='100%' frameborder='0' scrolling='no'></iframe></figure>

          </ScrollAnimation>
          <ScrollAnimation animateOnce={true} animateIn="fadeIn">
            
          <p>
            <strong>HOW HAS YOUR INDUSTRY SHIFTED DURING THE PANDEMIC?<br /></strong>
            Events and contests can’t operate in the same ways they used to. Our radio and programming studios also had to change the way we produce our shows and appeal to listeners. With so many people now working from home, smart speakers and app listening have seen a huge increase.
          </p>

          </ScrollAnimation>
          <ScrollAnimation animateOnce={true} animateIn="fadeIn">
          
          <p>
            <strong>HOW DID CAL STATE FULLERTON PREPARE YOU FOR YOUR CAREER?</strong><br />
            Learning how various parts of the entertainment industry operate helped me form a foundation for my career. My classes also helped me become a better writer and communicator. You’d be amazed at how much being a decent writer helps with your success.
          </p>

          </ScrollAnimation>
          <ScrollAnimation animateOnce={true} animateIn="fadeIn">
          
          <p>
            <strong>DO YOU HAVE ADVICE FOR STUDENTS ABOUT WORKING IN RADIO?</strong><br />
            There is no one path to get where you want to go. What worked for one person may not necessarily work for you, and there is no “right way” to do things. Say “yes” to any opportunity you can. You never know what connections or opportunities will become fruitful later. Start working hard on your craft now. Start that podcast. Make those videos. Write those blogs. If you work hard at it, opportunities will come. <span className="end-mark" />
          </p>

          </ScrollAnimation>
          <ScrollAnimation animateOnce={true} animateIn="fadeIn">


          <p className="learn-more">Support the College of Communications<br /><a href="https://campaign.fullerton.edu/comm" target="_blank" rel="noreferrer">campaign.fullerton.edu/comm</a></p>

          </ScrollAnimation>
        
        </article>
        <MoreStories slug={slug} />
      </Layout>
    );
  }
} 